import * as React from "react";
import {useEffect} from "react";
import {graphql, navigate, useStaticQuery} from "gatsby";
import Seo from "../Components/Seo";

// markup
const IndexPage = () => {
  return (
    <div className="container">
      <Seo title={"Welcome to Work Nicer"} />
      <List />
    </div>
  );
};

export default IndexPage;

const List = () => {
  const itemsFromBuild = useStaticQuery(graphql`
    query {
      soData {
        id
        opening_time
        name
        locations {
          slug
        }
      }
    }
  `);

  useEffect(() => {
    if (
      itemsFromBuild.soData.locations.length > 0 &&
      itemsFromBuild.soData.locations[0].slug
    ) {
      navigate(`${itemsFromBuild.soData.locations[0].slug}`);
    }
  }, []);

  return null;
};
